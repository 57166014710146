


































































































































































































































































import { defineComponent } from '@vue/composition-api';
import _ from 'lodash';

export default defineComponent({
  name: 'ExploreGuide2',
  components: {}
});
